import { postRequest, getRequest } from './index'
// 挂牌
// 列表接口
export const ordersList = (data, successCallback, failureCallback) => {
  postRequest('/order_s/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 列表接口添加
export const addOrdersList = (data, successCallback, failureCallback) => {
  postRequest('/order_s/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 列表接口修改状态
export const updateOrdersList = (data, successCallback, failureCallback) => {
  postRequest('/order_s/updateState', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 列表接口详情
export const ordersDetails = (data, successCallback, failureCallback) => {
  getRequest('/order_s/findById', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
