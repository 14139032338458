<template>
  <!-- 新增采购合同页面 -->
  <div class="addProcurementContract page-info-content">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" :inline="true" label-width="140px" class="demo-ruleForm">
      <el-form-item label="企业合同编号">
        <el-input v-model="ruleForm.goodsName" disabled placeholder="系统自动生成" />
      </el-form-item>
      <el-form-item prop="goodsId" label="合作企业">
        <el-select v-model="ruleForm.csgType" class="cmpNameSearch" placeholder="请选择">
          <el-option label="买方自提" value="01" />
          <el-option label="卖方送货" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item prop="goodsId" label="客户名称">
        <el-select v-model="ruleForm.csgType" class="cmpNameSearch" placeholder="请选择">
          <el-option label="买方自提" value="01" />
          <el-option label="卖方送货" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item prop="goodsName" label="货款金额">
        <el-input v-model="ruleForm.goodsName" />
      </el-form-item>
      <el-form-item prop="specs" label="仓库">
        <el-select v-model="ruleForm.csgType" class="cmpNameSearch" placeholder="请选择">
          <el-option label="买方自提" value="01" />
          <el-option label="卖方送货" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item prop="materials" label="交货方式">
        <el-select v-model="ruleForm.csgType" class="cmpNameSearch" placeholder="请选择">
          <el-option label="买方自提" value="01" />
          <el-option label="卖方送货" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item prop="weights" label="交货详细地址">
        <el-input v-model="ruleForm.weights" />
      </el-form-item>
      <el-form-item prop="productions" label="结算周期">
        <div class="astrictBox">
          <el-input v-model="ruleForm.productions" /><span>元</span>
        </div>
      </el-form-item>
      <el-form-item label="采购协议" prop="warshouse">
        <FileUpload />
      </el-form-item>
      <el-form-item prop="goodsId" label="关联销售合同">
        <el-select v-model="ruleForm.csgType" class="cmpNameSearch" placeholder="请选择">
          <el-option label="一票制" value="01" />
          <el-option label="多票制" value="02" />
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.csgType" type="textarea" maxlength="200" show-word-limit placeholder="请输入" />
      </el-form-item>
      <div class="generationGoods">
        <h4 class="title">
          采购货物明细：
        </h4>
        <div class="tableBox">
          <table>
            <tr>
              <td>操作</td>
              <td>品名</td>
              <td>材质</td>
              <td>规格</td>
              <td>产地</td>
              <td>件重(吨)</td>
              <!-- <td>采购数量</td> -->
              <td>采购数量</td>
              <td>采购单价(元)</td>
              <td>价格合计(元)</td>
            </tr>
            <tr v-for="(item, index) in tableList" :key="index">
              <td>
                <i class="icon el-icon-remove-outline" @click="deleteRow(index)" />
                <i class="icon el-icon-circle-plus-outline" @click="addRow" />
              </td>
              <td>
                <el-select v-model="item.goodsIds" filterable placeholder="请选择品名" @change="getPname(item, index)">
                  <el-option v-for="val in pnameData" :key="val.code" :label="val.goodsName" :value="val.code" />
                </el-select>
              </td>
              <td @click="verificationPname(item, index)">
                <el-input v-model="item.materials" placeholder="选择品名联动" />
              </td>
              <td @click="verificationPname(item, index)">
                <el-input v-model="item.productions" placeholder="选择品名联动" />
              </td>
              <td>产地</td>
              <td @click="verificationPname(item, index)">
                <el-input v-model="item.weights" placeholder="选择品名联动" />
              </td>
              <!-- <td>
                <el-input v-model="item.productions" type="number" placeholder="请选择" />
              </td> -->
              <td>
                <el-input v-model="item.productions" type="number" placeholder="请选择" />
              </td>
              <td>
                <el-input v-model="item.productions" type="number" placeholder="请选择" />
              </td>
              <td>123</td>
            </tr>
          </table>
        </div>
      </div>
    </el-form>
    <div class="buttonCombination">
      <el-button size="small" type="primary" :loading="$store.state.loading" @click="submitForm('ruleForm')">
        确定
      </el-button>
      <el-button size="small" @click="resetForm('ruleForm')">
        取消
      </el-button>
    </div>
    <ChooseMatterWeight :id="pnameId" ref="chooseMatterWeight" @getCheckId="getCheckId" />
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import ChooseMatterWeight from '@/components/ChooseMatterWeight'
import { getGoodsList } from '@/http/agentApi/common'
import { addOrdersList, ordersDetails } from '@/http/agentApi/hangOutManagement'
export default {
  components: { ChooseMatterWeight, FileUpload },
  data() {
    return {
      ruleForm: {},
      pnameId: '',
      rules: {
        warshouse: [
          { required: true, message: '请输入仓库', trigger: 'blur' }
        ],
        invoiceType: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        valiDate: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        orderPrice: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        orderWeight: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        orderAmount: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        inventoryNo: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        allCity: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        csgType: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        goodsId: [
          { required: true, message: '请选择品名', trigger: 'change' }
        ]
      },
      tableList: [{}],
      pnameData: [],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/procurementContract/index', name: '采购合同管理' }, { path: '', name: '新增采购合同' }]
    }
  },
  mounted() {
    getGoodsList(res => {
      // 通过code为六位数的就是品名  取到这个就是第三级
      this.pnameData = res.data.filter(item => item.code.length === 6)
    }, () => { }, '')
    if (this.$route.query.id) {
      // 如果是编辑  就会带id过来  我们进行编辑
      ordersDetails({ id: this.$route.query.id }, res => {
        this.ruleForm = { ...res.data }
      })
    }
  },
  methods: {
    // 输入件重  材质  产地规格等的验证
    verificationPname(item, index) {
      this.changePnameIndex = index
      if (!item.goodsIds) {
        this.$message.error('请先选择品名')
        return
      }
      this.$refs.chooseMatterWeight.dialogTableVisible = true
      // 进行判断  如果品名是我们库里有的就弹窗  没有的就输入
      // let flag = this.pnameData.length === 0 ? false : this.pnameData.some(value => value.code === item.goodsIds)
      // if (flag) {
      //   this.$refs.chooseMatterWeight.dialogTableVisible = true
      //   this.pnameId = item.goodsIds;
      // }
    },
    getCheckId() {},
    // 获取品名时的联动
    getPname(value, index) {
      this.changePnameIndex = index
      this.$set(value, 'materials', '')
      this.$set(value, 'productions', '')
      this.$set(value, 'specs', '')
      this.$set(value, 'weights', '')
      // 进行判断  如果品名是我们库里有的就弹窗  没有的就输入
      const flag = this.pnameData.length === 0 ? false : this.pnameData.some(item => {
        this.$set(value, 'goodsName', item.goodsName)
        return item.code === value.goodsIds
      }
      )
      // 如果有就弹窗取数值
      if (flag) {
        this.$set(value, 'goodsId', value.goodsIds)
        this.pnameId = value.goodsIds
        this.$refs.chooseMatterWeight.dialogTableVisible = true
        return
      }
      // 没有的话就把名字写成id就可以了   并后端不需要goodsId
      this.$set(value, 'goodsId', '')
      this.$set(value, 'goodsName', value.goodsIds)
    },
    // 代采商品列表的添加与删除
    addRow() {
      this.tableList.push({})
    },
    deleteRow(index) {
      this.tableList.splice(index, 1)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addOrdersList(this.ruleForm, () => {
            this.$message.success('成功！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.ruleForm.imageReqList = []
    }
  }

}
</script>

<style lang="scss">
.addProcurementContract .el-form {
  padding: 32px 0;

  .generationGoods {
    padding: 0 32px;

    .tableBox {
      width: calc(100% - 130px);
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: 18px;
      margin-left: 110px;

      table {
        width: 120%;
        margin-top: 16px;
        border-collapse: collapse;
        font-size: 15px;

        td {
          border: 1px solid #f5f5f5;
          height: 38px;
          text-align: center;
          width: 120px;

          .icon {
            color: #333;
            font-weight: 700;
            font-size: 22px;
            cursor: pointer;
            margin: 0 6px;
          }

          .el-input__inner {
            border: 0;
            text-align: center;
          }
        }

        .addButton {
          padding: 4px 0;

          >span {
            margin-right: 12px;
          }
        }
      }
    }

    .title {
      margin-top: 12px;

      span {
        font-weight: 500;
      }
    }
  }
}

.addProcurementContract .buttonCombination {
  text-align: center;
}

.addProcurementContract .el-form .el-form-item {
  width: 32%;
}

.addProcurementContract .el-form .el-form-item__content {
  width: calc(100% - 140px);

  .astrictBox {
    display: flex;

    span {
      text-align: right;
    }

    .left {
      text-align: left;
    }
  }
}

.addProcurementContract .el-form .lastOne {
  width: 100%;
}

.addProcurementContract .el-form .el-input__inner,
.addProcurementContract .el-form .el-select,
.addProcurementContract .el-form .el-input,
.addProcurementContract .el-form .el-cascader {
  width: 100%;
}
</style>
